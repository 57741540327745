<template>
  <div>
    <v-card style="padding: 10px">
      <v-form ref="first_form" v-model="form_one">
        <v-row>
          <v-col>
            <v-text-field :rules="[v => !!v || 'Item is required!']" id="product_name" v-model="product_name" outlined
              dense :label="translate('product name')" hide-details></v-text-field>
          </v-col>

          <v-col>
            <v-text-field :rules="[v => !!v || 'Item is required!']" id="buy_price" v-model="buy_price" outlined dense
              :label="translate('Buy Price (no Tax)')" hide-details></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field :rules="[v => !!v || 'Item is required!']" id="sell_price" v-model="sell_price" outlined dense
              :label="translate('Sell Price (no Tax)')" hide-details></v-text-field>
          </v-col>

          <v-col>
            <v-select :rules="[v => !!v || 'Item is required!']" outlined dense v-model="measurement_id"
              :label="translate('Measurement')" :items="getMeasurements"></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select :rules="[v => !!v || 'Item is required!']" outlined dense :label="translate('Machine')"
              v-model="selected_machine" item-value="id" item-text="name" :items="machines"></v-select>
          </v-col>
          <v-col>
            <v-select :rules="[v => !!v || 'Item is required!']" outlined dense :label="translate('Category')"
              v-model="selected_category" item-value="id" item-text="name" :items="categories"></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-file-input :rules="[v => !!v || 'Item is required!']" dense flat v-model="image" label="File input" filled
              prepend-icon="mdi-camera"></v-file-input>
          </v-col>
          <v-col>
            <v-text-field :rules="[v => !!v || 'Item is required!']" id="sku" v-model="sku" outlined dense
              :label="translate('SKU')" hide-details></v-text-field>
          </v-col>
        </v-row>
        <v-tabs v-model="selected_tab">
          <v-tab>{{ translate('Single Product') }}</v-tab>
          <v-tab>{{ translate('Mixed Product') }}</v-tab>
        </v-tabs>

        <div v-if="selected_tab == 1" class="text-right">
          <br />
          <button data-v-6fcca708="" v-on:click="
            selected_products.push({
              id: Math.random() * 10000,
              product_id: products[0].product_id,
              amount: 0,
            })
            " type="button"
            class="v-btn v-btn--is-elevated text-right v-btn--has-bg theme--light v-size--default primary">
            <span class="v-btn__content">{{ translate('Buy') }}</span>
          </button>
          <br />
          <br />
          <v-simple-table height="auto" max-height="500" fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center text-uppercase">ID</th>

                  <th class="text-center text-uppercase">{{ translate('Product') }}</th>

                  <th class="text-center text-uppercase">
                    {{
                      translate('Amount')
                    }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </th>

                  <th class="text-center text-uppercase">{{ translate('Actions') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="stock in selected_products" :key="stock.id">
                  <td class="text-center">
                    <v-text-field @input="product_id_changed(stock)" v-model="stock['product_id']" :label="'ID'"
                      :rules="[v => !!v || 'Item is required']" clear-icon="shrink" outlined type="number"
                      dense></v-text-field>
                  </td>
                  <td class="text-center" style="padding: 10px">
                    <v-select :rules="[v => !!v || 'Item is required']" dense v-model="stock['product_id']" class="shrink"
                      outlined @input="id => product_selected(stock, id)" :items="getProducts"></v-select>
                  </td>

                  <td class="text-center" style="padding: 10px">
                    <v-text-field :label="translate('Amount')" :key="key" v-model="stock['amount']"
                      :suffix="stock.measurement_name" outlined dense :rules="[v => !!v || 'Item is required!']" :min="1"
                      type="number"></v-text-field>
                  </td>

                  <td class="text-center">
                    <div v-on:click="selected_products = selected_products.filter(item => item !== stock)"
                      class="v-avatar elevation-1 rounded error" style="height: 30px; min-width: 30px; width: 30px">
                      <span aria-hidden="true" class="v-icon notranslate theme--dark white--text"
                        style="font-size: 25px; height: 25px; width: 25px"><svg xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg"
                          style="font-size: 25px; height: 25px; width: 25px">
                          <path :d="icons.mdiCloseCircleOutline"></path>
                        </svg></span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-form>
      <v-col class="text-right">
        <v-btn color="primary" v-on:click="submit"> {{ translate('Submit') }} </v-btn>
        <v-btn v-on:click="cancel" type="reset" class="mx-2" outlined> {{ translate('Cancel') }} </v-btn>
      </v-col>
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import axios from 'axios'
import { mdiPlus, mdiCloseCircleOutline } from '@mdi/js'
import { editProduct, getMeasurements, getProducts, newProduct, getCategories, getMachines } from '@/ApiManager';

export default {
  props: ['id'],

  computed: {
    getMeasurements() {
      return this.measurements.map(a => {
        return { text: a.name, value: a.id }
      })
    },
    getProducts() {
      return this.products.map(a => {
        return { text: a.name, value: a.id }
      })
    },
  },
  methods: {
    product_selected(stock, name) {
      for (var i = 0; i < this.products.length; i++) {
        if (this.products[i].name) {
          stock['product_id'] = this.products[i].id
          stock['measurement_name'] = this.products[i].measurement_name
          this.key += 1
          return
        }
      }
    },
    product_id_changed(stock) {
      for (var i = 0; i < this.products.length; i++) {
        if (this.products[i].id == stock['product_id']) {
          console.log(this.products[i])
          stock['product_id'] = this.products[i].id
          stock['product_name'] = this.products[i].name
          stock['measurement_name'] = this.products[i].measurement_name
          this.key += 1
          return
        }
      }
    },
    product_selected(one, two) {
      one.product_id = two
    },
    machine_selected(measurement_name) {
      for (var i = 0; i < this.machines.length; i++) {
        if (this.machines[i].name == measurement_name) {
          this.machine_id = this.machines[i].id
        }
      }
    },
    category_selected(measurement_name) {
      for (var i = 0; i < this.categories.length; i++) {
        if (this.categories[i].name == measurement_name) {
          this.category_id = this.categories[i].id
        }
      }
    },
    measurement_selected(measurement_name) {
      for (var i = 0; i < this.measurements.length; i++) {
        if (this.measurements[i].name == measurement_name) {
          this.measurement_id = this.measurements[i].id
        }
      }
    },
    cancel: function (_) {
      this.$router.go(-1)
    },
    submit: function (_) {
      if (this.$refs.first_form.validate() == false) {
        return
      }
      this.$store.state.loading = true
      var _products = this.selected_products.map(a => {
        return { id: a.product_id, amount: a.amount }
      })
      console.log(_products)
      editProduct(
        this.$route.query.id,
        JSON.stringify(_products),
        this.selected_tab,
        this.product_name,
        this.buy_price,
        this.sell_price,
        this.measurement_id,
        this.sku,
        this.image,
        this.selected_category,
        this.selected_machine
      ).then(response => {
        console.log(response)
        this.$router.push({ name: 'products' })
      })
    },
  },
  async created() {
    this.$store.state.loading = true

    this.$store.state.loading = true
    var response = await getMeasurements();
    this.measurements = response;
    response = await getCategories();
    this.categories = response;
    response = await getMachines();
    this.machines = response
    response = await getProducts();
    this.products = response.filter(item => item.type == 'single')
    this.$store.state.loading = false
    this.products = response.filter(item => item.type == 'single')

    for (var i = 0; i < response.length; i++) {
      var product = response[i]
      if (product.id == this.$route.query.id) {
        this.product_name = product.name
        this.measurement_id = product.measurement_id
        this.sell_price = product.sell_price
        this.buy_price = product.buy_price
        this.sku = product.sku
        const _response = await fetch(product["image_url"]);
        const blob = await _response.blob();
        const file = new File([blob], product["image_url"]);
        this.image = (file);
        if (product.type == 'mixed') this.selected_tab = 1
        for (var ii = 0; ii < product.products.length; ii++) {
          var _product = product.products[ii]
          this.selected_products.push({ id: Math.random() * 10000, product_id: _product.id, amount: _product.amount })
        }
        for (var ii = 0; ii < this.categories.length; ii++) {
          if (this.categories[ii].id == product.category_id) {
            this.selected_category = this.categories[ii].id
          }
        }
        for (var ii = 0; ii < this.machines.length; ii++) {
          if (this.machines[ii].id == product.category_id) {
            this.selected_machine = this.machines[ii].id
          }
        }
      }
    }

    this.$store.state.loading = false

  },
  data() {
    return {
      form_one: false,
      form_two: false,
      selected_tab: null,
      //this list contains the raw materials that the user have chosen
      measurements: [],
      measurement_id: 0,
      key: 0,
      machines: [],
      categories: [],
      selected_category: null,
      selected_machine: null,
      products: [],
      selected_products: [],
      sku: null,
      image: null,
    }
  },
  setup() {
    const product_name = ref('')
    const leaflet_key = ref('')
    const inventory_address = ref('')
    const sell_price = ref('')
    const buy_price = ref('')
    const amount = ref('')
    return {
      icons: {
        mdiPlus,
        mdiCloseCircleOutline,
      },
      product_name,
      leaflet_key,
      inventory_address,
      sell_price,
      buy_price,
      amount,
    }
  },
}
</script>
<style scoped>
@import url('https://unpkg.com/leaflet@1.6.0/dist/leaflet.css');

.component-container {
  display: table;

  @media (pointer: none),
  (pointer: coarse) {
    width: calc(100% - (50%));
  }
}

.component-row {
  display: table-row;
}

.component-row div {
  position: relative;
  display: table-cell;
}

.component-row div:nth-child(2) {
  left: 20px;
}

.component-row div:nth-child(3) {
  left: 40px;
}
</style>